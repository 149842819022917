import React, { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import './copytext.css';

const CopyableText = ({ text = "npx linkeshvaranr" }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <div className="copyable-text-container">
      <p className="copyable-text">{text}</p>
      <div className="copy-icon-container" onClick={handleCopy}>
        <FaRegCopy className="copy-icon" />
      </div>
      {isCopied && <span className="copied-text">Copied!</span>}
    </div>
  );
};

export default CopyableText;
